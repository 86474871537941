<template>
    <show-manual-pics
    :fileNames="['17.png']"/>
</template>

<script>
import showManualPics from '../components/modules/show-manual-pics.vue';
export default {
  components: { showManualPics },

}
</script>